/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// Function
@function tickIcon2($r, $g, $b) {
  @return url('data:image/svg+xml;utf-8,<svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.999817 6L6.99982 11L14.9998 1" stroke="rgb(#{$r},#{$g},#{$b})" stroke-width="2" stroke-linecap="round"/></svg>');
}
@function checkIconCircle($r, $g, $b) {
  @return url('data:image/svg+xml;utf-8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22C9.34784 22 6.8043 20.9464 4.92893 19.0711C3.05357 17.1957 2 14.6522 2 12H2ZM11.4293 16.28L17.1867 9.08267L16.1467 8.25067L11.2373 14.3853L7.76 11.488L6.90667 12.512L11.4293 16.2813V16.28Z" fill="rgb(#{$r},#{$g},#{$b})"/></svg>');
}

:root {
  // --ion-font-family: "sofia-pro", sans-serif;
  // --ion-font-family: "sofia-pro-soft", sans-serif;
  --ion-font-family: "sofia-pro", sans-serif;
  --ion-font-family-soft: "sofia-pro-soft", sans-serif;
  --font-family: "sofia-pro", sans-serif;
  --font-family-soft: "sofia-pro-soft", sans-serif;
  // --ion-font-family-med: "sofia-pro-soft-med", sans-serif;
  // --ion-font-family-bold: "sofia-pro-soft-bold", sans-serif;

  --header-height: 70px; // 54px;
  --footer-height: 70px;

  // --calendar-icon: url('data:image/svg+xml;utf-8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 2h-.667V.667A.667.667 0 0 0 12.667 0H12a.667.667 0 0 0-.667.667V2H4.667V.667A.667.667 0 0 0 4 0h-.667a.667.667 0 0 0-.666.667V2H2C.897 2 0 2.897 0 4v10c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm.667 12c0 .367-.3.667-.667.667H2A.668.668 0 0 1 1.333 14V6.693h13.334V14z" fill="rgb(46,24,53)"/><path fill="rgb(46,24,53)" d="M2.286 8h3.429v3.429H2.286z"/></svg>');
  --tick-icon: url('data:image/svg+xml;utf-8,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.5,25.5a2,2,0,0,1-1.41-.59l-5.5-5.5a2,2,0,0,1,2.83-2.83l4.09,4.09L24.59,7.59a2,2,0,0,1,2.83,2.83l-14.5,14.5A2,2,0,0,1,11.5,25.5Z" fill="white"/></svg>');
  // --tick-icon-2: url('data:image/svg+xml;utf-8,<svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.999817 6L6.99982 11L14.9998 1" stroke="rgb(46, 24, 53)" stroke-width="2" stroke-linecap="round"/></svg>');
  // --search-icon: url('data:image/svg+xml;utf-8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.05 0a7.05 7.05 0 10.01 14.1A7.05 7.05 0 007.05 0zm0 12.8a5.75 5.75 0 110-11.51 5.75 5.75 0 010 11.5z M15.8 14.89l-3.72-3.73a.65.65 0 10-.92.92l3.73 3.73a.65.65 0 00.92 0 .65.65 0 000-.92z" fill="rgb(46,24,53)" /></svg>');
  --angle-down-icon: url('data:image/svg+xml;utf-8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 6L8.75 11.25L3.5 6" stroke="rgb(131, 125, 134)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');

  --ion-color-primary: rgb(46, 24, 53);
  --ion-color-primary-rgb: 46, 24, 53;
  --ion-color-medium: rgb(131, 125, 134);
  --ion-color-light-tint: rgb(249, 249, 249);
  --ion-color-warning-tint: rgb(250, 205, 60);
  --ion-color-warning-tint-rgb: 250, 205, 60;
  --ion-color-warning: rgba(250, 157, 50, 1);
  --ion-color-success: rgba(92, 191, 106, 1);
  --ion-color-blue: #6f8df4;
  --ion-color-blue-rgb: 111, 141, 244;

  --success: #5cbf6a;
  --success-rgb: 92, 191, 106;

  --pending: #6f8df4;
  --pending-rgb: 111, 141, 244;

  --grey: #a7a1a9;
  --grey-rgb: 167, 161, 169;

  --like: #ff5454;

  --budget: #b4aee8;
  --budget-rgb: 180, 174, 232;
  --over-budget: #ff6955;
  --over-budget-rgb: 255, 105, 85;
  --under-budget: #1ff3ce;
  --under-budget-rgb: 31, 243, 206;

  --body-color: #f9f9f9;

  --header-bg-color: #160334;

  // Yellow
  --ion-color-yellow: #facd3c;
  --ion-color-yellow-rgb: 250, 205, 60;
  --ion-color-yellow-contrast: #2e1835;
  --ion-color-yellow-contrast-rgb: 46, 24, 53;
  --ion-color-yellow-shade: #dcb435;
  --ion-color-yellow-tint: #fbd250;

  --ion-color-orange: #fa9d32;
  --ion-color-orange-rgb: 250, 157, 50;
  --ion-color-orange-contrast: #000000;
  --ion-color-orange-contrast-rgb: 0, 0, 0;
  --ion-color-orange-shade: #dc8a2c;
  --ion-color-orange-tint: #fba747;

  --ion-color-green: #69bb7b;
  --ion-color-green-rgb: 105, 187, 123;
  --ion-color-green-contrast: #ffffff;
  --ion-color-green-contrast-rgb: 255, 255, 255;
  --ion-color-green-shade: #5ca56c;
  --ion-color-green-tint: #78c288;

  --ion-color-gray: #837d86;
  --ion-color-gray-rgb: 131, 125, 134;
  --ion-color-gray-contrast: #ffffff;
  --ion-color-gray-contrast-rgb: 255, 255, 255;
  --ion-color-gray-shade: #736e76;
  --ion-color-gray-tint: #8f8a92;

  --ion-color-white-success: #ffffff;
  --ion-color-white-success-rgb: 255, 255, 255;
  --ion-color-white-success-contrast: #69bb7b;
  --ion-color-white-success-contrast-rgb: 105, 187, 123;
  --ion-color-white-success-shade: #f1f1f1;
  --ion-color-white-success-tint: #ffffff;

  --hugo-mid-gray: #e4e1e4;
  --hugo-mid-gray-rgb: 228, 225, 228;
  --hugo-mid-gray2: #d0cfd1;
  --hugo-mid-gray2-rgb: 208, 207, 209;
  --hugo-green: #27ae60;
  --hugo-green-rgb: 39, 174, 96;
  --hugo-yellow: #ffbf00;
  --hugo-yellow-rgb: 255, 191, 0;
  --hugo-red: #eb5757;
  --hugo-red-rgb: 235, 87, 87;
  --hugo-space-grey: #837d86;
  --hugo-space-grey-rgb: 131, 125, 134;
  --hugo-grey-light: #fafafa;
  --hugo-grey-light-rgb: 250, 250, 250;
  --hugo-light-grey: #f3f2f3;
  --hugo-light-grey-rgb: 243, 242, 243;

  --ion-color-hugo-green: var(--hugo-green);
  --ion-color-hugo-green-rgb: var(--hugo-green-rgb);
  --ion-color-hugo-green-contrast: #ffffff;
  --ion-color-hugo-green-contrast-rgb: 255, 255, 255;
  --ion-color-hugo-green-shade: #229954;
  --ion-color-hugo-green-tint: #3db670;

  --ion-color-hugo-yellow: var(--hugo-yellow);
  --ion-color-hugo-yellow-rgb: var(--hugo-yellow-rgb);
  --ion-color-hugo-yellow-contrast: #2e1835;
  --ion-color-hugo-yellow-contrast-rgb: 46, 24, 53;
  --ion-color-hugo-yellow-shade: #e0a800;
  --ion-color-hugo-yellow-tint: #ffc51a;

  // Social Network
  --whatsapp: #25d366;
  --instagram: #e4405f;
  --twitter: #1da1f2;
  --facebook: #1877f2;
  --linkedin: #0a66c2;

  // Scroll Bar
  --scroll-bar-width: 5px;
}

::-ms-reveal {
  display: none !important;
}

::-webkit-scrollbar {
  width: var(--scroll-bar-width);
  height: var(--scroll-bar-width);
}
::-webkit-scrollbar-thumb {
  background: rgba(var(--ion-color-gray-rgb), 0.5);
  // border-radius: var(--scroll-bar-width);
}
::-webkit-scrollbar-track {
  background: var(--white-color);
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

// ion color classes
.ion-color-yellow {
  --ion-color-base: var(--ion-color-yellow);
  --ion-color-base-rgb: var(--ion-color-yellow-rgb);
  --ion-color-contrast: var(--ion-color-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow-shade);
  --ion-color-tint: var(--ion-color-yellow-tint);
}
.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: #fff;
  --ion-color-contrast-rgb: 255, 255, 255;
  // --ion-color-contrast: var(--ion-color-orange-contrast);
  // --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}
.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}
.ion-color-gray {
  --ion-color-base: var(--ion-color-gray);
  --ion-color-base-rgb: var(--ion-color-gray-rgb);
  --ion-color-contrast: var(--ion-color-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray-shade);
  --ion-color-tint: var(--ion-color-gray-tint);
}

.ion-color-white-success {
  --ion-color-base: var(--ion-color-white-success);
  --ion-color-base-rgb: var(--ion-color-white-success-rgb);
  --ion-color-contrast: var(--ion-color-white-success-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-success-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-success-shade);
  --ion-color-tint: var(--ion-color-white-success-tint);
}

.ion-color-hugo-green {
  --ion-color-base: var(--ion-color-hugo-green);
  --ion-color-base-rgb: var(--ion-color-hugo-green-rgb);
  --ion-color-contrast: var(--ion-color-hugo-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-hugo-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-hugo-green-shade);
  --ion-color-tint: var(--ion-color-hugo-green-tint);
}

.ion-color-hugo-yellow {
  --ion-color-base: var(--ion-color-hugo-yellow);
  --ion-color-base-rgb: var(--ion-color-hugo-yellow-rgb);
  --ion-color-contrast: var(--ion-color-hugo-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-hugo-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-hugo-yellow-shade);
  --ion-color-tint: var(--ion-color-hugo-yellow-tint);
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--ion-font-family);
}
* {
  &:focus {
    outline: none;
  }
}

/**--Common classes--**/
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.text-white {
  color: #fff;
}
.text-yellow {
  color: var(--ion-color-yellow);
}
.text-orange {
  color: var(--ion-color-orange);
}
.text-success {
  color: var(--success);
}
.text-blue {
  color: var(--ion-color-blue);
}
.text-primary {
  color: var(--ion-color-primary);
}
.text-hugo-mid-gray2 {
  color: var(--hugo-mid-gray2);
}
.text-hugo-red {
  color: var(--hugo-red);
}
.text-hugo-yellow {
  color: var(--hugo-yellow);
}
.text-gray {
  color: var(--ion-color-gray);
}
.text-hugo-space-grey {
  color: var(--hugo-space-grey);
}

.text-transform-initial {
  text-transform: initial;
}
.text-transform-capitalize {
  text-transform: capitalize;
}
.text-transform-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg-transparent {
  background: transparent;
}

.first-letter-uppercase {
  &::first-letter {
    text-transform: uppercase;
  }
}
.no-ripple {
  --ripple-color: transparent;
  --background: transparent;
  --background-hover: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
}

.mt-20 {
  margin-top: 20px;
}

.p-0 {
  padding: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.m-0 {
  margin: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.m-auto {
  margin: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.d-none {
  display: none !important;
}
.d-ib {
  display: inline-block;
}
.va-m {
  vertical-align: middle;
}

.overflow-x-auto {
  overflow-x: auto;
}

.no-keyboard-offset {
  --keyboard-offset: 0 !important;
}

.h-100 {
  height: 100%;
}
.min-h-100 {
  min-height: 100%;
}
.max-h-100 {
  max-height: 100%;
}
.w-100 {
  width: 100%;
}
.w-100-f {
  width: 100% !important;
}

.pos-rel {
  position: relative;
}
.z-index-1 {
  z-index: 1;
}

.cursor-pointer {
  cursor: pointer;
}

/* Energy Saving Tips Icons */
[class^="icon-"]:before,
[class*=" icon-"]:before {
  margin: 0;
  line-height: 1;
  width: auto;
  font-size: 24px;
  vertical-align: middle;
}

/**--Common CSS--**/
main.inner-scroll {
  background: transparent !important;
  text-align: center;
}
ion-content {
  --padding-start: 10px !important;
  --padding-end: 10px !important;

  --padding-top: var(--header-height);

  // &.main-content {
  //   --padding-top: 57px;
  // }

  // &.ios {
  //   &.main-content {
  //     --padding-top: 55px;
  //   }
  // }

  &.grey-bg {
    --background: var(--ion-color-light-tint);
  }

  &.p-0 {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
  }
}

ion-header {
  background: transparent; // #fff;
  // pointer-events: none;
}

ion-button {
  letter-spacing: normal;
  text-transform: capitalize;
}

// .page-header {
//   background: var(--ion-color-primary);
//   margin: 0;
//   color: #fff;
//   text-align: center;
//   padding: 20px 0;
//   border-top-left-radius: 34px;
//   border-top-right-radius: 34px;
//   position: relative;

//   h1 {
//     margin: 0;
//     font-size: 26px;
//     color: #fff;
//   }

//   p {
//     font-size: 16px;
//     color: #fff;
//     margin: 0;
//   }
//   p:last-child {
//     line-height: 20px;
//   }

//   &:after {
//     content: "";
//     position: absolute;
//     left: 0;
//     right: 0;
//     top: 99%;
//     height: 30px;
//     background: var(--ion-color-primary);
//   }
// }

// .btn-container {
//   .sign-btn {
//     background: #ffbf00;
//     color: #150233;
//     font-size: 18px;
//     font-weight: 600;
//     border-radius: 30px;
//     padding: 15px 28px;
//     height: 54px;
//     text-transform: capitalize;
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     margin: auto;
//     line-height: 24px;
//     align-items: center;

//     .button-inner {
//       justify-content: space-between;
//     }
//     &:focus {
//       outline: none;
//     }
//   }

//   ion-icon {
//     vertical-align: text-top;
//   }
//   &:focus {
//     outline: none;
//   }
// }

.errorTxtMsg,
.error-msg {
  color: var(--ion-color-danger);
  font-size: 12px;
  margin: -7px 0 12px;
}

.searchbar-input-container {
  .searchbar-input {
    font-size: 16px;
    box-shadow: none;
  }
}

.ion-activatable {
  position: relative;
  overflow: hidden;
}
// .swiper-wrapper{
//     @media (min-width:600px){
//         justify-content: center;
//     }
// }

// .shadows {
//   box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
//     0 1px 5px 0 rgba(0, 0, 0, 0.12);
// }

// .my-custom-modal-css {
//   --max-height: 285px;

//   .modal-wrapper {
//     position: absolute;
//     bottom: 0;
//   }
//   .sign-btn {
//     background: #ffbf00;
//     --background: #ffbf00;
//     --background-activated: #ffbf00;
//     --background-focused: #ffbf00;
//     --background-hover: #ffbf00;
//     color: #150233;
//     font-size: 18px;
//     font-weight: 600;
//     border-radius: 30px;
//     padding: 15px 28px;
//     height: 54px;
//     text-transform: capitalize;
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     margin: auto;
//     line-height: 24px;
//     align-items: center;

//     --border-style: none;
//     --box-shadow: none;
//     .button-inner {
//       justify-content: space-between;
//     }
//     &:focus {
//       outline: none;
//     }
//   }
// }

// Alert
.alert-button.sc-ion-alert-md:last-child {
  font-weight: 700;
}

// Full Page Loader
// .loader-full-page {
//   --spinner-size: 15;
//   --spinner-border-width: 3;
//   --spinner-border-color: #666;
//   --loader-text-color: #666;

//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-wrap: wrap;
//   flex-direction: column-reverse;
//   color: var(--loader-text-color);
//   text-align: center;
//   width: 100%;
//   height: 100%;

//   &::before {
//     content: attr(data-text);
//     display: block;
//     margin-top: 15px;
//   }

//   &::after {
//     content: "";
//     border: calc(1px * var(--spinner-border-width)) solid;
//     border-color: var(--spinner-border-color) transparent transparent;
//     width: calc(1px * var(--spinner-size));
//     height: calc(1px * var(--spinner-size));
//     display: block;
//     animation: button-spin 0.6s linear;
//     animation-iteration-count: infinite;
//     border-radius: 50%;
//     box-shadow: 0 0 0 1px transparent;
//   }
// }

// @keyframes button-spin {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

// <!-- Account Page
.account-page {
  // &__header {
  //   --header-bottom-offset: 12px;

  //   border-radius: 30px 30px 0 0;
  //   background: var(--ion-color-primary);
  //   margin-bottom: calc(-1 * var(--header-bottom-offset));

  //   &::after {
  //     content: "";
  //     display: block;
  //     width: 100%;
  //     height: var(--header-bottom-offset);
  //     background: var(--ion-color-primary);
  //   }
  // }
  // &__title {
  //   padding: 21px 15px 27px;
  //   font-size: 21px;
  //   text-align: center;
  //   color: #fff;
  //   margin: 0;
  // }

  // &__content {
  //   padding-top: 15px;

  //   position: relative;
  //   z-index: 1;
  // }

  &__card {
    background: #fff;
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px;

    &--rounded {
      border-radius: 50px;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &-header {
      padding: 20px 15px 0;
    }
    &-title {
      margin: 0;
      font-size: 15px;
      font-weight: 600;
    }
  }

  &__list {
    &-item {
      padding: 20px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.align-items-start {
        align-items: flex-start;
      }
      &.flex-direction-column {
        flex-direction: column;
      }

      &:not(:last-child) {
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.08);
      }
    }
    &-left {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      min-width: 0;

      &-icon {
        width: 14px;
        height: auto;
        margin-right: 10px;
        margin-top: 2px;
        display: block;
      }
      &-inner {
        width: 100%;
        min-width: 0;

        > *:last-child {
          margin-bottom: 0;
        }
      }

      &.align-items-center {
        align-items: center;
      }
    }
    &-right {
      display: flex;
      align-items: center;

      .angle-right-icon,
      .arrow-next-icon {
        --padding-start: 0;
        --padding-end: 0;

        margin: 0 auto;
        padding: 0;
        width: 40px;
        min-height: initial;
        height: auto;
        display: block;

        img {
          display: block;
          margin-left: auto;
          height: auto;
        }
      }
      .angle-right-icon {
        img {
          width: 6.75px;
        }
      }
      .arrow-next-icon {
        img {
          width: 14px;
        }
      }

      .cta-button {
        --padding-start: 15px;
        --padding-end: var(--padding-start);
        --padding-top: 8px;
        --padding-bottom: var(--padding-top);

        font-size: 14px;
        // font-weight: 600;
        margin: 0;
        min-height: initial;
        height: auto;
        display: block;
        overflow: visible;
        position: relative;
        text-transform: capitalize;
        letter-spacing: normal;

        &__text {
          position: relative;
          z-index: 2;
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 12px;
          right: 10px;
          bottom: 0;
          left: 10px;
          z-index: 1;
          box-shadow: 0px 2px 10px rgba(var(--ion-color-yellow-rgb), 0.5),
            0px 2px 30px rgba(0, 0, 0, 0.1);
        }
      }
    }

    // &-top {}
    &-bottom {
      .expand-wrapper {
        padding: 0 !important;

        &:not(.collapsed) {
          margin-top: 10px;
        }
      }
    }

    &-title,
    &-sub-title,
    &-text {
      &:not(.no-truncate) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-title {
      margin: 0 0 8px;
      font-size: 15px;
      line-height: 1.35;
      font-weight: 600;
    }
    &-sub-title {
      margin: 0 0 5px;
      font-size: 15px;
      line-height: 1.35;
    }
    &-text {
      margin: 0;
      font-size: 14px;
      line-height: 1.25;
      color: rgba(var(--ion-color-primary-rgb), 0.6);
      // display: flex;
      // align-items: center;
      .checbox-circle,
      .dot,
      span {
        display: inline-block;
        vertical-align: middle;
      }
      .checbox-circle,
      .dot {
        width: var(--size);
        height: var(--size);
      }
      .checbox-circle {
        --size: 10px;
        margin-right: 5px;
      }

      .dot {
        --size: 4px;
        margin: 0 6px;
        background: rgba(var(--ion-color-primary-rgb), 0.6);
        border-radius: 50%;
      }
    }
  }

  // &__cta-button {
  //   font-size: 14px;
  //   // font-weight: 600;
  //   border-radius: 50px;
  //   height: auto;
  //   text-transform: capitalize;
  //   width: 100%;
  //   margin: 0;
  //   line-height: 20px;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   letter-spacing: normal;

  //   --padding-start: 30px;
  //   --padding-end: var(--padding-start);
  //   --padding-top: 15px;
  //   --padding-bottom: var(--padding-top);
  //   --border-style: none;
  //   --box-shadow: none;

  //   --icon-width: 14px;
  //   --icon-height: 12px;

  //   &:focus {
  //     outline: none;
  //   }
  //   &--disable {
  //     opacity: 0.5;
  //     cursor: none;
  //   }

  //   &-text {
  //     display: block;
  //     width: 100%;
  //     text-align: left;
  //     line-height: 20px;
  //     // font-weight: 600;
  //   }
  //   // &-icon {}
  // }

  // &__textarea,
  &__input {
    --background: transparent;
    --border-width: 0;
    --background-focused: #fff;
    border-radius: 12px;

    .item-ios,
    .item-md,
    &.item-input {
      // &.item-textarea {
      --background: #fff;
      --border-color: transparent;
      --padding-start: 15px;
      --padding-end: var(--padding-start);
      --inner-padding-end: 0;

      border-radius: 12px;
      border: 1px solid #efefef;
      margin-bottom: 12px;

      // .text-input-md, .text-input-ios {
      //     margin: 15px 8px;
      // }

      // ion-textarea,
      ion-input {
        // textarea,
        input {
          padding: 15px 0;
          line-height: 20px;
          font-size: 14px;
        }
      }

      // &.ion-invalid {
      //   border-color: var(--ion-color-danger);
      // }
    }
    &.item-select {
      --background: #fff;
      --border-color: transparent;
      --padding-start: 15px;
      --padding-end: var(--padding-start);
      --inner-padding-end: 0;

      border-radius: 12px;
      border: 1px solid #efefef;
      margin-bottom: 12px;
    }

    ion-select {
      width: 100%;
      max-width: 100%;
      --padding-start: 0;
    }

    // &.ion-invalid {
    //   --highlight-background: transparent;
    //   // border-color: var(--ion-color-danger) !important;
    // }

    // &--disabled {
    //   --background: rgba(0,0,0,.05);
    // }
    &--readonly {
      .item-ios,
      .item-md,
      &.item-input,
      &.item-textarea {
        --background: rgba(0, 0, 0, 0.05) !important;
        pointer-events: unset;
      }
    }
  }
  // &__input {
  //   &--date-time {
  //     --border-color: transparent;
  //     --padding-start: 0;
  //     --inner-padding-end: 0;
  //     --background-activated: transparent;
  //     min-height: initial;
  //     width: 100%;
  //   }

  //   ion-datetime {
  //     --background: #fff;
  //     --border-color: transparent;
  //     --inner-padding-end: 0;
  //     --placeholder-color: rgba(var(--ion-color-primary-rgb), 0.6);
  //     --color: var(--ion-primary-color);
  //     --padding-start: 15px;
  //     --padding-end: var(--padding-start);
  //     --padding-top: 14px;
  //     --padding-bottom: var(--padding-top);

  //     background-image: var(--calendar-icon);
  //     background-size: 16px 16px;
  //     background-position: calc(100% - 15px) center;
  //     background-repeat: no-repeat;

  //     font-size: 14px;
  //     width: 100%;
  //     border-radius: 12px;
  //     border: 1px solid #efefef;
  //     // margin-bottom: 15px;

  //     // &.ion-invalid {
  //     //   border-color: var(--ion-color-danger);
  //     // }
  //   }

  //   &--with-button {
  //     --padding-end: 0px !important;
  //     ion-button {
  //       position: absolute;
  //       right: 4px;
  //       top: 50%;
  //       transform: translateY(-50%);
  //       z-index: 10;

  //       width: 40px;
  //       height: 40px;
  //       box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
  //       border-radius: 10px;
  //       margin: 0;

  //       --icon-width: 16px;
  //       --icon-height: var(--icon-width);
  //     }
  //   }
  // }

  .ionic-selectable-icon-inner {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 2.5px;
  }
}
// Account Page -->

// <!-- toggle switch
.switch {
  $this: &;

  position: relative;
  display: inline-block;
  width: 37px;
  height: 14px;
  margin: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      + .slider {
        background-color: var(--ion-color-yellow);
        box-shadow: inset -5px -3px 7px #ffe181, inset 5px 0px 7px #dba702;

        &::before {
          transform: translateX(19px);
        }
      }
    }
  }

  &--blue {
    input {
      &:checked {
        + .slider {
          background-color: var(--ion-color-blue);
          box-shadow: inset -5px -3px 7px #9cb2ff, inset 5px 0px 7px #4a6ce0;
        }
      }
    }
  }

  &--primary {
    input {
      &:checked {
        + .slider {
          background-color: var(--ion-color-primary);
          box-shadow: none;
        }
      }
    }
  }

  &--electric,
  &--gas {
    .slider {
      background: var(--ion-color-gray);

      &::before {
        // background-image: var(--icon-img);
        background-size: 18px 18px;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
  &--electric {
    .slider {
      &::before {
        background-image: url("/assets/svg/icon_electric_mid-gray.svg");
      }
    }

    input {
      &:checked {
        + .slider {
          background: var(--ion-color-yellow);
          box-shadow: none;

          &::before {
            background-image: url("/assets/svg/icon_electric.svg");
          }
        }
      }
    }
  }
  &--gas {
    .slider {
      &::before {
        background-image: url("/assets/svg/icon_gas_mid-gray.svg");
      }
    }

    input {
      &:checked {
        + .slider {
          background: var(--ion-color-blue);
          box-shadow: none;

          &::before {
            background-image: url("/assets/svg/icon_gas.svg");
          }
        }
      }
    }
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--hugo-mid-gray2); // #ccc;
  transition: 0.4s;
  border-radius: 14px;

  &::before {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    left: -1px;
    bottom: -3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 0px 0px 4px rgba(76, 73, 77, 0.35);
  }
}

// Toggle switch -->

ion-spinner {
  vertical-align: middle;
}

ion-picker {
  &:not(.picker-ios) {
    .picker-opt {
      font-size: 16px;
      line-height: 21px;
      &.picker-opt-selected {
        font-size: 18px;
        line-height: 24px;
        border: 1px solid var(--ion-color-primary);
        border-radius: 4px;
      }
    }
  }
}

// Quick Fix
// .contractpage {
//   .ionic-selectable {
//     .ionic-selectable-icon-inner {
//       top: 24px;
//       // top: 50%;
//       // transform: translateY(-50%);
//     }
//   }
// }

.divider {
  $this: &;
  display: flex;
  align-items: center;
  justify-content: space-between;

  --sz: 16px;
  --px: 25px;
  // --offset: calc(50% - var(--px));
  --text-padding: 0;

  &::before,
  &::after {
    content: "";
    display: block;
    background: rgba(var(--ion-color-gray-rgb), 1); //0.13
    width: var(--line-width);
    height: var(--line-height);
    flex-grow: 1;
  }
  &--v {
    --line-width: 1px;
    --line-height: 100%;
    --text-padding: var(--px) 0;
    // --line-height: var(--offset);
  }
  &--h {
    // --line-width: var(--offset);
    --line-width: 100%;
    --line-height: 1px;
    --text-padding: 0 var(--px);
  }

  &__text {
    font-size: 15px;
    font-weight: 600;
    color: rgba(var(--ion-color-gray-rgb), 1); //0.13
    // width: var(--sz);
    // height: var(--sz);
    line-height: var(--sz);
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: var(--text-padding);
  }
}

.form {
  // &__ion-item {
  //   font-size: 14px;

  //   &--text-uppercase {
  //     ion-input {
  //       input {
  //         text-transform: uppercase !important;

  //         &::-webkit-input-placeholder {
  //           text-transform: capitalize;
  //         }
  //         &::placeholder {
  //           text-transform: capitalize;
  //         }
  //       }
  //     }
  //   }
  // }
  // &__input,
  // &__textarea {
  //   --background: transparent;
  //   --border-width: 0;
  //   border-radius: 12px;

  //   &:not(:last-child) {
  //     margin-bottom: 15px;
  //   }

  //   .item-ios,
  //   .item-md,
  //   &.item-input,
  //   &.item-textarea {
  //     --background: #fff;
  //     --border-color: transparent;
  //     --padding-start: 15px;
  //     --padding-end: var(--padding-start);
  //     --inner-padding-end: 0;

  //     border-radius: 12px;
  //     border: 1px solid #efefef;

  //     ion-input,
  //     ion-textarea {
  //       input,
  //       textarea {
  //         padding: 15px 0;
  //         line-height: 20px;
  //         font-size: 14px;
  //       }
  //     }
  //   }
  //   &.item-select {
  //     --background: #fff;
  //     --border-color: transparent;
  //     --padding-start: 15px;
  //     --padding-end: var(--padding-start);
  //     --inner-padding-end: 0;

  //     border-radius: 12px;
  //     border: 1px solid #efefef;
  //   }

  //   ion-select {
  //     width: 100%;
  //     max-width: 100%;
  //     --padding-start: 0;
  //   }

  //   &.isDisabled {
  //     opacity: 0.65;
  //     --icon-color: rgba(var(--ion-color-primary-rgb), 0.65);
  //   }
  // }
  // &__input {
  //   &--date-time {
  //     --border-color: transparent;
  //     --padding-start: 0;
  //     --inner-padding-end: 0;
  //     --background-activated: transparent;
  //     min-height: initial;
  //     width: 100%;
  //   }

  //   ion-datetime {
  //     --background: #fff;
  //     --border-color: transparent;
  //     --inner-padding-end: 0;
  //     --placeholder-color: rgba(var(--ion-color-primary-rgb), 0.6);
  //     --color: var(--ion-primary-color);
  //     --padding-start: 15px;
  //     --padding-end: var(--padding-start);
  //     --padding-top: 14px;
  //     --padding-bottom: var(--padding-top);

  //     background-image: var(--calendar-icon);
  //     background-size: 16px 16px;
  //     background-position: calc(100% - 15px) center;
  //     background-repeat: no-repeat;

  //     font-size: 14px;
  //     width: 100%;
  //     border-radius: 12px;
  //     border: 1px solid #efefef;
  //   }

  //   &--with-button {
  //     --padding-end: 0px !important;
  //     ion-button {
  //       position: absolute;
  //       right: 4px;
  //       top: 50%;
  //       transform: translateY(-50%);
  //       z-index: 10;

  //       width: 40px;
  //       height: 40px;
  //       box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
  //       border-radius: 10px;
  //       margin: 0;

  //       --icon-width: 16px;
  //       --icon-height: var(--icon-width);
  //     }
  //   }

  //   &--postcode {
  //     ion-input {
  //       input {
  //         text-transform: uppercase !important;

  //         &::-webkit-input-placeholder {
  //           text-transform: capitalize;
  //         }
  //         &::placeholder {
  //           text-transform: capitalize;
  //         }
  //       }
  //     }
  //   }

  //   &--select {
  //     border: 1px solid #efefef;

  //     ion-label {
  //       font-size: 14px !important;
  //       --color: rgba(var(--ion-color-primary-rgb), 0.65) !important;
  //       display: flex !important;
  //       align-items: center;
  //       justify-content: space-between;
  //       min-height: 33px;
  //     }
  //     --icon-width: 12px;
  //     --icon-height: 8px;
  //     --icon-color: var(--ion-color-primary);
  //     --inner-border-width: 0;

  //     .ionic-selectable-value {
  //       padding-top: 15.5px !important;
  //       padding-bottom: 15.5px !important;
  //     }

  //     &.isDisable {
  //       ion-label {
  //         min-height: 31px;
  //       }
  //     }
  //     .ionic-selectable {
  //       --padding-start: 0;
  //       max-width: 100%;
  //       width: 100%;
  //     }
  //   }
  // }

  // &__textarea {
  //   &--with-char-counter {
  //     position: relative;

  //     .char-counter {
  //       display: block;
  //       position: absolute;
  //       right: 0;
  //       bottom: 5px;
  //       font-size: 14px;
  //       color: rgba(var(--ion-color-primary-rgb), 0.5);
  //     }
  //   }
  // }

  .ionic-selectable-has-placeholder .ionic-selectable-value-item {
    color: rgba(var(--ion-color-primary-rgb), 0.65);
    font-size: 14px;
    font-weight: normal;
  }
  .ionic-selectable-icon-inner {
    // top: 50%;
    // transform: translateY(-50%);
    // margin-top: 2.5px;
    border: 0;
    width: 12px;
    height: 8px;
    background-image: url("/assets/svg/angle-down.svg");
    background-repeat: no-repeat;
    margin: 0;
    top: 50% !important;
    transform: translateY(-50%);
  }
  &__btns {
    box-shadow: 0px 2px 30px rgb(0, 0, 0, 0.1);
    border-radius: 50px;
  }
  &__cta-btn {
    font-size: 18px;
    // font-weight: 600;
    border-radius: 50px;
    height: auto;
    text-transform: capitalize;
    width: 100%;
    margin: 0;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: normal;

    --padding-start: 30px;
    --padding-end: var(--padding-start);
    --padding-top: 14px;
    --padding-bottom: 16px; // var(--padding-top)
    --border-style: none;
    --box-shadow: none;

    &:focus {
      outline: none;
    }
    &--disable {
      opacity: 0.5;
      cursor: none;
    }

    &--outline {
      --border-width: 2px;
      &[color="yellow"] {
        border: var(--border-width) solid var(--ion-color-yellow);
        --icon-color: var(--ion-color-yellow);
      }
      &[color="hugo-yellow"] {
        border: var(--border-width) solid var(--ion-color-hugo-yellow);
        --icon-color: var(--ion-color-hugo-yellow);
      }
    }

    &.button-disabled {
      &[color="hugo-yellow"] {
        --ion-color-base: var(--ion-color-gray);
        --opacity: 1;
      }
    }

    &-text {
      display: block;
      width: 100%;
      text-align: left;
      line-height: 24px; // 20px
      font-weight: 500;

      &.text-center {
        text-align: center;
      }
    }
    &-icon {
      &--arrow-right {
        --icon-width: 14px;
        --icon-height: 12px;
      }
    }
  }
}

.cst-ion-back-btn {
  --icon-width: 15px;
  --icon-height: 12px;
  --sz: 30px;
  display: flex !important;
  align-items: center;
  height: var(--sz) !important;
  width: var(--sz) !important;
  min-width: initial !important;
  min-height: initial !important;

  .button-native {
    display: none;
  }
}

ionic-selectable-modal {
  ion-header {
    pointer-events: auto;
  }

  ion-content {
    --padding-top: 12px;
    --padding-bottom: 12px;
  }
}

// ion-modal {
//   ionic-selectable-modal {
//     ion-header {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       position: relative;

//       ion-toolbar {
//         &:first-of-type {
//           position: absolute;
//           right: 20px;
//           top: 50%;
//           transform: translateY(-50%);
//           z-index: 11;
//           width: 38px;
//           --padding-bottom: 0;
//           --padding-end: 0;
//           --padding-start: 0;
//           --padding-top: 0;
//           --background: transparent;

//           ion-title {
//             display: none;
//           }
//         }

//         &.toolbar-searchbar {
//           ion-searchbar {
//             padding-top: 15px;
//             height: auto;
//             min-height: 70px;
//           }
//         }
//       }
//     }
//   }
// }

// Custom Modal
.cst-modal-wrapper {
  $this: &;

  --min-height: var(--height);
  --max-height: var(--height);

  z-index: 20000 !important;
  align-items: flex-end;
  opacity: 1;

  ion-backdrop {
    background: transparent;
    opacity: 1;

    &.sc-ion-modal-md {
      // Added for fixing below error getting on android devices
      // [Intervention] Ignored attempt to cancel a touchstart event with cancelable=false, for example because scrolling is in progress and cannot be interrupted.
      &.backdrop-no-tappable {
        pointer-events: none;
      }
    }
  }

  .modal-wrapper {
    position: absolute;
    bottom: 0;
  }

  &:not(#{$this}--full-page) {
    background: rgba(var(--ion-color-primary-rgb), 0.8);

    .modal-wrapper {
      max-width: 414px;
      border-radius: 18px 18px 0 0;
    }
  }

  &--full-page {
    --height: 100%;
    --width: 100%;
    --border-radius: 0;
  }

  &--mpan {
    --height: 375px !important;
  }
  &--create-tip {
    --height: 383px !important;
  }
  &--select-icon {
    --height: 383px !important;
    background: transparent !important;
  }
  // &--help-success {}

  &--help-content {
    --height: 300px !important;
  }

  &--current-tarrif {
    --height--height: 300px !important;
  }
}

// custom Alert
.cst-alert-dialog {
  $this: &;

  ion-backdrop {
    &.sc-ion-alert-md {
      // Added for fixing below error getting on android devices
      // [Intervention] Ignored attempt to cancel a touchstart event with cancelable=false, for example because scrolling is in progress and cannot be interrupted.
      &.backdrop-no-tappable {
        pointer-events: none;
      }
    }
  }
}

// Ion Alert
ion-alert {
  --max-width: 300px !important;

  .alert-wrapper {
    padding: 25px 20px;
    border-radius: 22px !important;

    .alert-head {
      padding-top: 0;
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
    .alert-title {
      margin-top: 0;
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
      color: var(--ion-color-primary);
    }
    .alert-message {
      font-size: 14px;
      padding-left: 0;
      padding-right: 0;
      color: rgba(var(--ion-color-primary-rgb), 0.8);
    }
    .alert-button-group {
      padding: 0 5px;
    }
    .alert-button {
      font-weight: 500 !important;
      font-size: 14px;
      border-top: 0;

      &:first-child {
        border-right: 0;
      }
      &:last-child {
        background: var(--ion-color-yellow);
        box-shadow: 0px 8px 25px rgba(var(--ion-color-warning-tint-rgb), 0.24);
        border-radius: 50px;
      }
    }
  }
}

// hide disbale options from list
// .scf-ion-popover,
// .scu-ion-popover {
//   .list-ios {
//     margin-bottom: 0;

//     ion-item {
//       &:last-child {
//         --inner-border-width: 0;
//       }

//       &.item-interactive-disabled {
//         height: 0;
//         overflow: hidden;
//         display: none;
//         --inner-border-width: 0;
//       }
//     }
//   }
// }
// .scf-ion-popover,
.remove-bottom-space {
  .list-ios {
    margin-bottom: 0;

    ion-item {
      &:last-child {
        --inner-border-width: 0;
      }
    }
  }
}

// Ion Loader
ion-loading {
  .loading-wrapper {
    border-radius: 12px;
  }
}

// // HTML Canvas

// #html2canvas {
//   .est {
//     &__list-icon {
//       --sz: 120;
//     }
//   }
// }

.cst-checkbox {
  $this: &;
  display: inline-flex;
  align-items: center;
  margin: 0;

  &__btn {
    --btn-size: 30;
    width: calc(var(--btn-size) * 1px);
    height: calc(var(--btn-size) * 1px);
    border-radius: calc((var(--btn-size) * 0.266666666) * 1px);
    position: relative;
    border: calc((var(--btn-size) * 0.066666666) * 1px) solid var(--color);
    // display: inline-block;
    // vertical-align: middle;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  &__text {
    margin-left: 10px;
    font-size: 13px;
    line-height: 16px;
    color: #fff;
  }

  &__input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;

    &:focus + {
      #{$this}__btn {
        box-shadow: 0 0 0 calc((var(--btn-size) * 1px) / 2)
          rgba(var(--color-rgb), 0.1) inset;
      }
    }

    &:checked + {
      #{$this}__btn {
        background-color: var(--color);
        background-size: calc((var(--btn-size) * 0.466666666) * 1px)
          calc((var(--btn-size) * 0.333333333) * 1px);
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  &--hugo-yellow {
    --color: var(--hugo-yellow);
    --color-rgb: var(--hugo-yellow-rgb);

    #{$this}__input {
      &:checked + {
        #{$this}__btn {
          background-image: tickIcon2(46, 24, 53);
        }
      }
    }
  }

  &--primary {
    --color: var(--ion-color-primary);
    --color-rgb: var(--ion-color-primary-rgb);

    #{$this}__input {
      &:checked + {
        #{$this}__btn {
          background-image: tickIcon2(255, 255, 255);
        }
      }
    }
  }
}

// Form Fields
.form-fields {
  $this: &;

  &__item {
    --background: transparent;
    --inset-highlight-height: 0px;
    --highlight-background: transparent;
    --background-focused: transparent;

    --min-height: 50px;
    --padding-start: 18px;
    --padding-end: 18px;
    --inner-padding-end: 0;
    --inner-border-width: 0;

    height: 54px;
    border: 2px solid #fff;
    border-radius: 12px;
    background-color: #fff;
    margin-bottom: 16px;
    position: relative;

    &--valid {
      border-color: var(--hugo-green);
      background-image: url("/assets/svg/check-circle.svg");
      background-position: calc(100% - 14px) center;
    }
    &--invalid {
      border-color: var(--hugo-red);
      background-image: url("/assets/svg/clarity-warning.svg");
      background-position: calc(100% - 18px) center;
    }

    &--valid,
    &--invalid {
      background-repeat: no-repeat;
      background-size: 24px 24px;

      #{$this}__item-input {
        // fix icon is getting by input
        width: calc(100% - 30px);
      }
    }

    &--ion-selectable {
      ion-input {
        --padding-end: 27px;
        text-overflow: ellipsis;
      }

      background-image: var(--angle-down-icon);
      background-repeat: no-repeat;
      background-size: 18px 18px;
      background-position: calc(100% - 16px) center;
    }

    &--select {
      ion-select {
        padding: 0;
        max-width: 100%;
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        background-image: var(--angle-down-icon) !important;
        background-repeat: no-repeat;
        background-size: 18px 18px;
        background-position: right center;
        color: var(--ion-color-primary);

        --padding-start: 0;
        --padding-end: 0;
        --placeholder-color: var(--ion-color-gray);
      }
    }

    &--with-button {
      --padding-end: 0px !important;
      ion-button {
        position: absolute;
        right: 4px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;

        width: 40px;
        height: 40px;
        box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin: 0;

        --icon-width: 16px;
        --icon-height: var(--icon-width);
      }
    }

    &--telephone {
      ion-label {
        font-size: 12px !important;
        line-height: 16px;
        transform: translate3d(0, 7px, 0) !important;
      }

      ion-input {
        bottom: 6px;

        &::before {
          content: attr(data-country-code);
          // background: rgba(0, 0, 0, 0.1);
          // padding: 0 5px;
          // border-radius: 4px;
          margin-right: 5px;
        }
      }
    }

    &--password {
      ion-input {
        width: calc(100% - 30px);
      }
    }

    &.item-has-focus,
    &.item-has-value {
      ion-label {
        font-size: 12px !important;
        line-height: 16px;
        transform: translate3d(0, 7px, 0) !important;
        // fix: label is getting cut when there is value in input
        z-index: 3;
      }
    }

    &.item-has-focus:not(#{$this}__item--valid):not(#{$this}__item--invalid) {
      border-color: var(--hugo-yellow);
    }

    .password-toggle-btn {
      position: absolute;
      width: 24px;
      height: 24px;
      // background: none;
      border: 0;
      margin: 0;
      padding: 0;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: block;
      z-index: 2;
      background: #fff;
    }
  }
  &__item-label {
    padding: 2px 0;
    margin: 0;
    transform: translate3d(0, 13px, 0) !important;
    font-size: 16px !important;
    line-height: 20px;
    color: var(--ion-color-gray) !important;
  }
  &__item-input {
    --min-height: 24px;
    --padding-top: 2px !important;
    --padding-bottom: 2px !important;
    --padding-end: 0; // 25px

    height: 24px;
    margin: 0;
    border: 0;
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    bottom: 15px;

    &.has-focus,
    &.has-value {
      bottom: 6px;
    }

    // input {
    //   &:-webkit-autofill,
    //   &:-webkit-autofill:hover,
    //   &:-webkit-autofill:focus,
    //   &:-webkit-autofill:active,
    //   &:-internal-autofill-selected {
    //     -webkit-box-shadow: 0 0 0 30px white inset !important;
    //   }
    // }
  }

  &__error {
    margin: -4px 0 16px;

    // padding-left: 18px;
    // background-image: url(clarity-warning.svg);
    // background-size: 14px 14px;
    // background-position: left center;
    // background-repeat: no-repeat;

    p {
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      margin: 0;
    }
  }
}

.form-fields-static {
  $this: &;

  &__item {
    --background: transparent;
    --inset-highlight-height: 0px;
    --highlight-background: transparent;
    --background-focused: transparent;

    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;
    --inner-border-width: 0;

    margin-bottom: 16px;
    position: relative;

    &.item-has-focus {
      #{$this}__item-input:not(#{$this}__item-input--valid):not(
          #{$this}__item-input--invalid
        ) {
        border-color: var(--hugo-yellow);
      }
    }
  }
  &__item-label {
    padding: 0;
    margin: 0 0 8px !important;
    font-size: 15px !important;
    line-height: 18px;
    color: #fff !important;
    transform: none !important;
  }
  &__item-input {
    --min-height: 54px;
    --padding-top: 7px !important;
    --padding-bottom: 9px !important;
    --padding-start: 15px !important;
    --padding-end: 40px !important;
    height: 54px;
    border: 2px solid #fff;
    border-radius: 8px;
    background-color: transparent;
    margin: 0;
    font-size: 24px;
    line-height: 24px;
    color: #fff;

    &--valid {
      border-color: var(--hugo-green);
      background-image: url("/assets/svg/check-circle.svg");
      background-position: calc(100% - 14px) center;
    }
    &--invalid {
      border-color: var(--hugo-red);
      background-image: url("/assets/svg/clarity-warning.svg");
      background-position: calc(100% - 18px) center;
    }

    &--valid,
    &--invalid {
      background-repeat: no-repeat;
      background-size: 24px 24px;
    }
  }

  &__error {
    margin: -4px 0 16px;
    p {
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      margin: 0;
    }
  }
}

.line-divider {
  width: 100%;
  height: 1px;
  background: var(--hugo-space-grey);
  margin: 20px auto;
}

// Stripe 3DS Iframe
.stripe-3ds-iframe {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  border: 0;
}

// Media Queries
@media (min-width: 768px) {
  .errorTxtMsg,
  .error-msg,
  .form-fields__error p {
    font-size: 14px;
  }
}
